import React from 'react'
import '../App.css'
import GetInvolvedSection from '../Components/GetInvolved/GetInvolvedSection'
import GISwhy from '../Components/GetInvolved/GISwhy';
function Services (){
    /*<GISform/>*/
    return (
        <>
        <GetInvolvedSection/>
        <GISwhy/>
        </>
    )
}

export default Services;