import React, {useState} from 'react'
import {FiMinus, FiPlus} from 'react-icons/fi'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import TabContent from './TabContent'
import TabList from './TabList'

function Collapsible({subsystem, index, toggle}) {

    //array that holds contents of sections (the divs?)
    //when tab is changed, content of div is changed to to that section

    const [value, setValue] = useState('one');

    const handleChange = (event, newValue) => {
        console.log("got clicked");
        setValue(newValue);
    };

    return (
        <div className={"subsystem" + (subsystem.open ? " open" : "")} key={index}>
            <div className="header" onClick={() => toggle(index)}>
                <span>{subsystem.open ? <FiMinus/> : <FiPlus/>}</span>
                {subsystem.title}
            </div>
            <div className="info-container">
                <div className="info">
                    <Box sx={{ width: '100%' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            textColor="primary"
                            indicatorColor="primary"
                            aria-label="subsystem-sections"
                        >
                            <Tab value="one" label="About" />
                            <Tab value="two" label="Activities" />
                            <Tab value="three" label="Skills" />
                        </Tabs>
                        <TabContent value={value} index={'one'} content={subsystem.description} image={subsystem.photo}/>
                        <TabContent value={value} index={'two'} content={
                            <TabList title={"Key activities"} name={"activities"} list={subsystem.activities}></TabList>
                        }/>
                        <TabContent value={value} index={'three'} content={
                            <TabList title={"Qualifications and Skills (desired, but not necessary)"} name={"skills"} list={subsystem.skills}></TabList>
                            }
                        />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Collapsible
