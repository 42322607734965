import React, {useState, useEffect} from 'react'
import { Link, useLocation, NavLink } from 'react-router-dom'
import './Navbar.css'
import '../../App.css'
//import {Link as LinkS} from 'react-scroll'
import {FaBars, FaTimes} from 'react-icons/fa'

function Navbar() {
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    const [button, setButton] = useState(true)

    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    }

    useEffect(() => {
        showButton();
    }, []) 

    window.addEventListener('resize', showButton)

    const location = useLocation()
    useEffect(()=> {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({behavior: "smooth"})
            }
        } else {
        window.scrollTo({top:0,left:0, behavior: "smooth"})
        }
    }, [location,])

    return (
        <>
         <nav className="navbar">
            <div className="navbar-container">
                <Link exact to="/" className="navbar-logo" onClick={closeMobileMenu}>
                    <img src='/images/logo.png' alt="Logo" className="logo"></img>
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                    {click? <FaTimes/> : <FaBars/>}
                </div>
                <ul className={click? 'nav-menu active' : 'nav-menu'}>
                    <li className="nav-item"> 
                        <NavLink exact to="/#Vision" className="nav-links" onClick={closeMobileMenu}>
                            Vision
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact to="/news" className="nav-links" onClick={closeMobileMenu} 
                        activeClassName="active-link">
                            News
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact to="/subsystems" className="nav-links" onClick={closeMobileMenu} 
                        activeClassName="active-link">
                            Subsystems
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact to="/team" className="nav-links" onClick={closeMobileMenu} 
                        activeClassName="active-link">
                            Team
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact to="/support-us" className="nav-links" onClick={closeMobileMenu} 
                        activeClassName="active-link">
                            Support Us
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink exact to="/get-involved" className="nav-links-mobile" onClick={closeMobileMenu} 
                        activeClassName="active-link">
                            Get Involved
                        </NavLink>
                    </li>
                </ul>
                <Link to="/get-involved" className="full-btn">
                    <button className="nav-btn">Get Involved</button>
                </Link>
            </div>
         </nav>   
        </>
    )
}

export default Navbar;
