import React from 'react'

//component that displays the info inside the tab
//takes in the index of the tab, the content (text), and the image if provided.
//styling for this component is done on the styling sheet for that page
function TabContent({value, index, content, image}) {

  return (
    <div hidden={value !== index} className="content">
        {image ? <img src={image} alt="subsystem" className={`pic-${index}`}></img> : null}
        <div className={`desc-${index}`}>
                {content}
        </div>
    </div>
  )
}

export default TabContent