export const sponsors = [
    {
        name: "Gill Instruments",
        img: "/images/sponsers/gill_logo.png",
        link: "http://gillinstruments.com/"
    },
    {
        name: "Ansys",
        img: "/images/sponsers/ansys_logo.png",
        link: "https://www.ansys.com/"
    },
    {
        name: "Altium",
        img: "/images/sponsers/Altium_Logo.jpg",
        link: "https://www.altium.com/"
    },
    {
        name: "Solid Works",
        img: "/images/sponsers/solidworks-logo-large-300x150.png",
        link: "https://www.solidworks.com/"
    },
    {
        name: "Actuonix",
        img: "/images/sponsers/actuonix.png",
        link: "https://www.actuonix.com/"
    },
    {
        name: "ATO",
        img: "/images/sponsers/ATO_logo.jpg",
        link: "https://www.ato.com/"
    },
    {
        name: "Evolugen",
        img: "/images/sponsers/Evolugen_Logo.png",
        link: "https://evolugen.com/"
    },
    {
        name: "Protocase",
        img: "/images/sponsers/protocase_logo.jpg",
        link: "https://www.protocase.com/"
    },
    {
        name: "University of Toronto",
        img: "/images/sponsers/University_of_Toronto_Logo.png",
        link: "https://www.utoronto.ca/"
    },
]