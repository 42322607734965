import React from 'react'
import '../App.css'
import HeroSection from '../Components/Team/HeroSection'
import Member from '../Components/Team/Member'
function Team (){
    return (
        <>
        <HeroSection/>
        <Member/>
        </>
    )
}

export default Team;