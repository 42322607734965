import React from 'react'
import '../../App.css'
import './HomeCard.css'

function HomeCard() {
    return (
        <div className="HomeCard">
            <div className="card-container">
                <video src='/videos/video.mp4' autoPlay loop muted></video>
                <h1>UTWind</h1>
                <h3>Face the Wind</h3>
            </div>
        </div>
    )
}

export default HomeCard
