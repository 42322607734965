import React, { useRef, useEffect } from 'react'
import './SlideShow.scss';
import Slider from './Slider';
import { IoIosArrowDropleft, IoIosArrowDropright } from 'react-icons/io'

const SlideShow = function({ images, autoPlay }) {
    const slideImage = useRef(null)
    const slideText = useRef(null)
    const slideTitle = useRef(null)
    const autoPlayRef = useRef()

    useEffect(() => {
        autoPlayRef.current = nextSlide
    }, [autoPlay])

    useEffect(() => {
        const play = () => {
          autoPlayRef.current()
        }
    
        const interval = setInterval(play, autoPlay * 1000)
        return () => clearInterval(interval)
    }, [autoPlay])
    

    const { previousSlide, nextSlide } = Slider(
        slideImage,
        slideText,
        slideTitle,
        images
    )

    return (
        <div className="slideshow" ref={ slideImage }>
            <div className="content">
                <button onClick={previousSlide} className="left-btn">
                    <IoIosArrowDropleft></IoIosArrowDropleft>
                </button>
                <div className="info">
                    <div className="header">
                        <h1 ref={slideTitle} className="title"></h1>
                    </div>
                    <div className="text">
                        <p ref={slideText} className="desc"></p>
                    </div>
                </div>
                <button onClick={nextSlide} className="right-btn">
                    <IoIosArrowDropright></IoIosArrowDropright>
                </button>
            </div>
        </div>
    )
}

export default SlideShow
