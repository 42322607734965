import React from 'react'
import {Link} from 'react-router-dom'
/* import './Footer.css' */
import './Footer.scss'

function Footer() {
    return (
        <div className="footer-container">
            <div className="wrap">
                <Link to="/" className="annoyingass">
                    <img src='/images/logo.png' alt="Logo" className="footer-logo"></img>
                </Link>
                <div className="info">
                    <div className="name">University of Toronto | UTWind</div>
                    <div className="email">myutwind@gmail.com</div>
                </div>
                <div className="social-icons-links">
                    <a href="https://www.facebook.com/MyUTWind/" rel="noreferrer" target="_blank">
                        <img src="/images/facebook.png" alt="facebook" className="social-icons"></img>
                    </a>
                    <a href="https://www.instagram.com/utwindclub/?hl=en" rel="noreferrer" target="_blank">
                        <img src="images/instagram.png" alt="instagram" className="social-icons"></img> 
                    </a>
                    <a href="https://www.linkedin.com/company/utwind/?originalSubdomain=ca" rel="noreferrer" target="_blank">
                        <img src="images/linkedin.png" alt="linkedin" className="social-icons"></img>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer

/* 
<body class>
    <div class="page-wrapper">
        <!--Main Content-->
    </div>
    <div class="footer">
        <!--Footer-->
            <div class="footer-content">
            <div class="footer-section about"></div>
            <div class="footer-section links"></div>
            <div class="footer-section contact"></div>
        </div>
    <div class="footer-bottom">
        &copy; University of Toronto | UTWind
    </div>
    </div>
</body>
</html> */


