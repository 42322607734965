import React from 'react'
import "./SupportUs.scss"
import {sponsors} from "./Sponsorships"

function SupportUsWhy() {
    return (
        <div className="about">
            <div className="text">
                <h1>Looking to Partner with UTWind?</h1>
                <p className='par'>UTWind is currently looking for partner organizations to help propel our team forward, especially to fund the building of the prototype. In return, we will post your name and logo here on our website with a link to your company website page. We will announce you as a sponsor on our social media sites, and include your logo on our turbine decal and team apparel, representing you on the U of Toronto campus and beyond.           </p>
                <br/>
                <p className='par'> Ultimately, your support will contribute to the development of future professionals, as this team has the potential to be a formative experience for students pursuing education in related subjects.
                    UTWind is registered with ULife, and will process your donation in accordance with the University of Toronto sponsorship guidelines. More information on sponsorship can be found in the <a href="https://drive.google.com/file/d/1trFpfMDMRg1YaEcFbeMJkf1hDa7Nynj4/view"> PDF brochure. </a>
                </p>
            </div>

            <h3>Current Sponsers</h3>
                <center>
                    <br/>
                    <hr size="1" width="50%" color="#070745"/> 
                </center>
            <div className="list">
                    {
                        sponsors.map((item) => {
                            return(
                                <div className='sponsors'>
                                    <a href={item.link} rel="noreferrer" target="_blank">
                                    <img src={item.img} alt={item.name} className="sponsor"></img>
                                    </a>
                                </div>
                            )
                        })
                    }
            </div>

        </div>
    )
}

export default SupportUsWhy
