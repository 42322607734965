import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Data } from './NewsData'
import './Timeline.scss'
import {VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component"
import 'react-vertical-timeline-component/style.min.css'
import {FaRegLightbulb} from 'react-icons/fa'

function Timeline() {
    
    //used the following post in order to get smooth scroll from about page to news
    //to get to thhe right element: https://stackoverflow.com/questions/61779236/how-to-navigate-to-another-page-with-a-smooth-scroll-on-a-specific-id-with-react

    const location = useLocation()

    useEffect(()=> {
            if (location.hash) {
                let elem = document.getElementById(location.hash.slice(1))
                if (elem) {
                    elem.scrollIntoView({behavior: "smooth", block: 'center'})
                }
            } else {
            window.scrollTo({top:0,left:0, behavior: "smooth"})
            }
    }, [location,])

    return (
        <div>
            <h1 className="title">More News</h1>
            <div className="vt">
                <VerticalTimeline>
                    {
                        Data.map((elements) => {
                            return(
                                <VerticalTimelineElement key={elements.id} date={elements.date} dateClassName="date" className="element" id={elements.id}
                                contentStyle={{ alignItems: 'center', background: '#FFE7CB', color: 'black', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }} icon={<FaRegLightbulb/>} iconStyle={{ background: '#070745', color: '#fff' }}>
                                        <h3 className="title">{elements.title}</h3>
                                        {elements.extras ? 
                                        <div className="extra">
                                            {elements.extras.map((butts, id) => {
                                                return <a href={elements.extraLinks[id]} className='extra-els' rel="noreferrer" target="_blank">
                                                            <div className="extra-butt">
                                                                {butts}
                                                            </div>
                                                       </a>
                                            })}
                                        </div> : null}
                                        <p className="info">{elements.info}</p>
                                        <a href={elements.link} className="link" rel="noreferrer" target="_blank">
                                            <div className="butt">{elements.btn_txt}</div>
                                        </a>
                                </VerticalTimelineElement>
                            )
                        })
                    }
                </VerticalTimeline>
            </div>
        </div>
    )
}

export default Timeline
