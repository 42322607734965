import React from 'react'

//title is the title of the content in the tab section
//name is the name of the list (skills or activities)
function TabList({title, name, list}) {
  return (
    <div className="list-container"> {title}
                                <ul className={`${name} list`}>
                                    {
                                    list.map((item, index) => {
                                                return (
                                                    <li className='list-item' key={index}>{item}</li>
                                                )
                                            })
                                    }
                                </ul>
    </div>
  )
}

export default TabList