import React from 'react'
import './Member.css'

function Member() {
    return (
    <>  
        <div className="members">
        <h1 className="members">Executive Team</h1>
        <div className="member-container">
            <div className="our-team">
                <div className="pic">
                    <img src="images/Headshots/abir.png" alt=""/>
                </div>
                <div className="team-content">
                    <h4 className="title">Abir Shahid</h4>
                    <span className="post">Co-President</span>
                </div>
            </div>

            <div className="our-team">
                <div className="pic">
                    <img src="images/Headshots/Kimia_Talaei.jpG" alt="" width="100" height="auto"/>
                </div>
                <div className="team-content">
                    <h4 className="title">Kimia Talaei</h4>
                    <span className="post">Co-President</span>
                </div>
            </div>

            <div className="our-team">
                <div className="pic">
                    <img src="images/Headshots/Ashley.jpg" alt="" width="100" height="auto"/>
                </div>
                <div className="team-content">
                    <h4 className="title">Ashley Best</h4>
                    <span className="post">Vice-President & Media Lead</span>
                </div>
            </div>
        </div>
        </div>
        <div className="members">
        <h1 className="members">Subsystem Leads</h1>
        <br/>
        <div className="member-container">
            <div className="our-team">
                <div className="pic">
                    <img src="" alt=""/>
                </div>
                <div className="team-content">
                    <h4 className="title">Abnash Bassi</h4>
                    <span className="post">Controls Lead</span>
                </div>
            </div>

            <div className="our-team">
                <div className="pic">
                    <img src="" alt="" width="100" height="auto"/>
                </div>
                <div className="team-content">
                    <h4 className="title">Alison Okumura</h4>
                    <span className="post">Power Systems Lead</span>
                </div>
            </div>

            <div className="our-team">
                <div className="pic">
                    <img src="images/Headshots/Patty_Liu.jpg" alt="" width="100" height="auto"/>
                </div>
                <div className="team-content">
                    <h4 className="title">Patty Liu</h4>
                    <span className="post"> Aerodynamics Lead</span>
                </div>
            </div>
            <div className="our-team">
                <div className="pic">
                    <img src="" alt="" width="100" height="auto"/>
                </div>
                <div className="team-content">
                    <h4 className="title">David Petriw</h4>
                    <span className="post">Mechanical & Manufacturing Lead</span>
                </div>
            </div>
            <div className="our-team">
                <div className="pic">
                    <img src="images/Headshots/Tejas_Bendre.png" alt="" width="100" height="auto"/>
                </div>
                <div className="team-content">
                    <h4 className="title">Tejas Bendre</h4>
                    <span className="post">Sustainability Lead</span>
                </div>
            </div>
        </div>
        </div>

        <div className="members">
        <h1 className="members">Faculty Advisors</h1>
        <div className="member-container">
            <div className="our-team">
                <div className="pic">
                    <img src="images/Headshots/Philippe_Lavoie.jpg" alt=""/>
                </div>
                <div className="team-content">
                    <h4 className="title">Dr. Philippe Lavoie</h4>
                    <span className="post">Professor & Associate Director - Research | UTIAS</span>
                </div>
            </div>

            <div className="our-team">
                <div className="pic">
                    <img src="images/Headshots/David_Zingg.pnG" alt="" width="100" height="auto"/>
                </div>
                <div className="team-content">
                    <h4 className="title">Dr. David W. Zingg</h4>
                    <span className="post">UofT Distinguished Professor of Computational Aerodynamics and Sustainable Aviation  | UTIAS</span>
                </div>
            </div>

            <div className="our-team">
                <div className="pic">
                    <img src="images/Headshots/Chandra_Singh.jpg" alt="" width="100" height="auto"/>
                </div>
                <div className="team-content">
                    <h4 className="title">Dr. Chandra Veer Singh</h4>
                    <span className="post">Associate Professor | Material Science Engineering</span>
                </div>
            </div>           
        </div>
        <br/>
        </div>

        </>
    )
}

export default Member
