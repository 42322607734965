import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Subsystems from './Pages/Subsystems';
import Footer from './Components/Footer/Footer';
import News from './Pages/News';
import SupportUs from './Pages/SupportUs'
import Team from './Pages/Team'
import Services from './Pages/Services'


function App() {
  return (
    <>
    <Router>
      <Navbar/>
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/subsystems" exact component={Subsystems}></Route>
          <Route path="/news" exact component={News}></Route>
          <Route path="/support-us" exact component={SupportUs}></Route>
          <Route path="/team" exact component={Team}></Route>
          <Route path="/get-involved" exact component={Services}></Route>
        </Switch>
      <Footer/>
    </Router>
    </>
  );
}

export default App;
