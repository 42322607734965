import React from 'react'
import '../../App.css'
import './HeroSection.css'

function HeroSection() {
    //adding video 
    return (
        <div class='hero-container'>
            <video src="/videos/pexels-design-team.mp4" autoPlay loop muted />
            <h1>Meet the Team</h1>
        </div>
    )
}

export default HeroSection
