import React from 'react'
import '../App.css'
import About from '../Components/Home/About'
import HomeCard from '../Components/Home/HomeCard'
import Vision from './Vision'

function Home() {
    return (
        <div>
           <HomeCard></HomeCard> 
           <About></About>
           <Vision/>
        </div>
    )
}

export default Home
