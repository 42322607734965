import React, {useState} from 'react'
import '../App.css'
import './Subsystems.scss'
import Collapsible from '../Components/Subsystems/Collapsible'

function Subsystems() {
    //array called subsystems to hold the data to be displayed the collapsibles
    const [subsystems, details] = useState([
        {
            title: "Aerodynamics",
            description: "How does wind even make the turbine spin? And how can we design the blades to get the most amount of power possible?",
            skills: ["A general background in fluid mechanics, aerodynamics, and CFD", "Programming experience with MATLAB", "CAD experience, preferably with SolidWorks", "CFD experience, preferably with ANSYS Fluent", "Familiarity with structural layout and design-for-manufacturing practices"],
            activities: ["Blade aerodynamic design and trade studies for design optimization", "Blade structural design", "High-fidelity aerodynamic and structural simulations with ANSYS", "Collaborating with the Mechanical and Manufacturing team during the manufacturing phase", "Collaborating with the Controls and Power teams to provide the necessary aerodynamics models and data", "Perform wind tunnel testing and/or field testing to characterize the performance of the prototype before the competition"],
            photo: '/images/aerodynamics.png',
            open: true
        },
        {
            title: 'Mechanical',
            description: "This section designs all the other mechanical components of the turbine besides the blades. Everything from a simple bearing mount to a complex pitch mechanism - all done here!",
            skills: ["Conceptual design", "CAD design with SolidWorks", "Stress analysis using hand calculations or design for manufacturing", "Experience with manufacturing or design for manufacturing", "Basic knowledge of material science", "Previous machining or woodworking experience/accreditation through the university"],
            activities: ["Concept generation and CAD design of mechanical components", "Stress analysis of structural components", "Outsourcing manufacturing of some components, while machining others in house", "Working with the Sustainability team to ensure materials and manufacturing processes used are environmentally friendly"],
            photo: '/images/mechanical.png',
            open: false
        },
        {
            title: "Power Systems",
            description: "Once the shaft is spinning, that's just the beginning! How do we take that energy and turn it into something useful like powering a lightbulb or charging a smartphone? That's the job of the generator, and all the power electronics that go with it.",
            skills: ["Electromechanical integration, power systems design", "General background in power electronics and 3-phase power", "PLECS simulation", "Experience with Altium, or any other PCB  software", "Practical experience such as soldering and testing"],
            activities: ["Power electronics circuit design", "Simulation of circuits in PLECS", "Bench-testing ideas", "Designing printed circuit boards in Altium", "Assembling and testing PCBs"],
            photo: '/images/power_systems.png'
            ,
            open: false
        },
        {
            title: "Electronics and Control Systems",
            description: "The control system measures and records data, processes it through a micro-controller, and then analyze and optimize performance.",
            skills: ["Embedded systems, micro-controllers, control", "General knowledge of control systems and digital electronics", "MATLAB and simulink", "C++ (experience in any other programming language can be useful)", "Basic familiarity with object oriented programming, multithreading, and data structures", "Altium, or any other PCB design software" ],
            activities: ["Modelling and simulation of wind turbine controls archtecture in MATLAB/Simulink", "Designing printed circuit boards (PCBs) in Altium to accomodate sensors, analog to digital converter (ADC), data transmitter modules", "Controller programming: The controller will be implemented on a Rasberry Pi Zero W board, which will read and write data, process user commands, and implement control logic"],
            photo: '/images/control_systems.png'
            ,
            open: false
        },
        {
            title: "Manufacturing",
            description: "Not much of a turbine when it's all still on paper, is it? This section is where we get our hands dirty and build what we design! We also are heavily involved in the design process, so the CAD wizards don't get too out of hand!",
            skills: ["Material selection, life cycle analysis, design for manufacturing, composites analysis, hands-on skills", "Conceptual design", "CAD design with SolidWorks", "Stress analysis using hand calculations or design for manufacturing", "Experience with manufacturing or design for manufacturing", "Basic knowledge of material science", "Previous machining or woodworking experience/accreditation through the university"],
            activities: ["Material selection and design for manufacturing", "Stress analysis of structural components", "Outsourcing manufacturing of some components, while machining others in house", "Working with the Sustainability team to ensure materials and manufacturing processes used are environmentally friendly"],
            photo: '/images/manufacturing.png'
            ,
            open: false
        },
        {
            title: "Sustainability",
            description: "Sustainability is a big motivation for wind turbines, but even wind turbines have an environmental footprint. We also consider socioeconomic sustainability, whether the turbine would be deployable in the developing world.",
            skills: ["Design for UX", "Basic knowledge of life cycle assessment", "General knowledge of sustainability practices in engineering", "Working with all the other technical teams to provide feedback and do research on the sustainability of their designs"],
            activities: ["Conducting a life cycle assessment", "Conducting a business case for rolling out the turbine", "Designing concepts to improve the local embeddedness of the turbine", "Working with all the other techincal teams to provide feedback and do research on the sustainability of their designs"],
            photo: '/images/sustainability.png',
            open: false
        },
    ])

    const toggle = index => {
        details(subsystems.map((subsystem, i) => {
            if (i === index) {
                subsystem.open = !subsystem.open
            } else {
                subsystem.open = false
            }

            return subsystem; //test this out later
        } ))
    }
    return (
        <div>
            <div className="header-pic">
                <img src='/images/subsystemPic.png' alt="header" className="idk"></img>
                <h1 className="title">Subsystems</h1>
            </div>
            <div className="subsystems">
                {subsystems.map((subsystem, i) => (
                    <Collapsible subsystem={subsystem} index={i} toggle={toggle}></Collapsible>
                ))}
            </div>
        </div>
    )
}

export default Subsystems
