import React from 'react'
import "./About.css"
import { Link } from 'react-router-dom'

function About() {

    return (
        <div className="about">
            <img src='/images/competition_turbine.jpg' alt="competition" className="pic"></img>
            <div className="text">
                <h1>About Us</h1>
                <p>We are a student group at the University of Toronto on a mission to design and build 
                    a small scale wind turbine! Students from a wide variety of backgrounds across 
                    engineering and environmental sciences are working to make this project come together.
                </p>
                <h3>Competition</h3>
                <p>
                UTWind has recently won first place overall in the 2022 International Small Wind Turbine Contest! 
                This competition is held annually at the Hanze University of Applied Science in Delft, Netherlands,
                and is participated by many universities around the world! 
                We are very grateful to all our sponsers for their support,
                and our team members that worked so hard. The picture on the right is our first place wind turbine 😄.
                See <Link to="/news/#news-1">here</Link> for more information about the competition!
                </p>
            </div>
        </div>
    )
}

export default About
