export const Data = [
    {
        id: "news-1",
        image: "/images/amsterdam_team.jpg",
        title: "U of T Engineering News Feature",
        date: "June 27, 2022",
        desc: "UTWind wins first at the International Small Wind Turbine Contest!",
        info: "After preparing and planning for months, our hard work has paid off! Thank you to everyone that has helped us come this far and supported us!",
        btn_txt: "Read article",
        link: "https://news.engineering.utoronto.ca/utwind-places-first-at-the-international-small-wind-turbine-contest/"
    },
    {
        id: "news-2",
        image: "/images/recruitment_2022.jpg",
        title: "Winter 2022 Recruitment",
        date: "January 4, 2022",
        desc: "Looking for talented students!",
        info: "We are still looking for talented students to join us! We are preparing for the 2022 international small wind turbine contest, and would like to welcome any experienced students who are interested to join us :)",
        btn_txt: "Learn more",
        link: "https://drive.google.com/file/d/16JOirllycw3qcXOb8f6v-p7W5SfKB68C/view?usp=sharing"
    },
    {
        id: "news-3",
        image: '/images/solidWorks.jpg',
        title: 'SolidWorks Workshop Series',
        date: 'February 1, 2021',
        desc: "We're holding workshops!",
        info: 'We are hosting SolidWorks training workshops! We have recently welcomed some new members, and we will be training them, but anyone is welcome to join if you would like to learn how to use state-of-the-art CAD software!',
        btn_txt: 'Sign up', 
        link: 'https://drive.google.com/file/d/1rCfgY1lnHvHlK_nCDZ5hVnUon9mq8LwT/view'
    },
    {
        id: "news-4",
        image: '/images/team.jpg',
        title: 'Incoming Member Survey',
        date: 'January 26, 2021',
        desc: "Spots are still open!",
        info: "If you missed our session on January 20, don't worry! You can still join UTWind. Complete the survey below to sign up! Check out our recruitment page for details. You will also want to read our onboarding document.",
        btn_txt: 'survey' ,
        link: 'https://docs.google.com/forms/d/e/1FAIpQLScpDcL-3KIJz0h7GwMEcitUwiTWLeOx9sVT2JFo9G8psbiuKA/viewform',
        extras: ['recruitment page', 'onboarding document'],
        extraLinks: ['https://www.utwind.com/get-involved', 'https://docs.google.com/document/d/1FpazXpGrzm5JFaTTwpkJ49v3HYqlcA4oTVdG-T_vNCQ/edit']
    },
    {
        id: "news-5",
        image: '/images/team.jpg',
        title: 'Recruiting Members!',
        date: 'January 12, 2021',
        desc: "Join our crew!",
        info: "Welcome to 2021! Why not make this the year you get involved in your first design team? It's a great way to meet new people, have fun, develop your technical skills, and gain practical experience all at once! Attend our Zoom session on January 20 at 7 PM to learn more.",
        btn_txt: 'details',
        link: 'https://www.utwind.com/get-involved'
    },
    {
        id: "news-6",
        image: '/images/leader.jpg',
        title: 'Looking for Leaders!',
        date: 'November 19, 2020',
        desc: "Become a leader!",
        info: "Looking for core members to join the team in leadership positions! Get a head start on the upcoming semester by planning the design phase.",
        btn_txt: 'details',
        link: 'https://www.utwind.com/get-involved'
    },
    {
        id: "news-7",
        image: '/images/leader.jpg',
        title: 'Hello World!',
        date: 'October 29, 2020',
        desc: 'UTWind is here!',
        info: "UTWind is now active! Head over to our LinkedIn page and follow us for future updates.",
        btn_txt: 'LinkedIn',
        link: 'https://www.linkedin.com/company/utwind/?originalSubdomain=ca'
    }
]