import React from 'react'
import '../../App.css'
import '../Team/HeroSection.css'

function GetInvolvedSection() {
    //adding video 
    return (
        <div class='hero-container'>
            <video src="/videos/getinvolved.mp4" autoPlay loop muted />
            <h1>Get Involved!</h1>
            <p>UTWind Welcomes Members of all Backgrounds and Skill Levels! </p>
            <div className="hero-btns">
            </div>
        </div>
    )
}

export default GetInvolvedSection
