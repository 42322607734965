import React from 'react'
import SlideShow from '../Components/News/SlideShow'
import {Data} from '../Components//News/NewsData'
import Timeline from '../Components/News/Timeline';

function News() {
    const display = 3;
    const displayedSlides = Data.slice(0, display); //array that only holds the latest 3 cards

    return (
        <div>
            <SlideShow images={displayedSlides} autoPlay={2}></SlideShow>
            <Timeline></Timeline>
        </div>
    )
}

export default News
