import React from 'react'
import '../App.css'
import SupportUsSection from '../Components/SupportUs/SupportUsSection';
import SupportUsWhy from '../Components/SupportUs/SupportUsWhy';
function SupportUs (){
    return (
        <>
        <SupportUsSection/>
        <SupportUsWhy/>
        </>
    )
}

export default SupportUs;