import React from 'react'

function VisionCard(props) {
    return (
        <div className="card-item">
            <div className="icon">
                <img src={props.icon} alt={props.header + " icon"} className="icon-pic"></img>
            </div>
            <div className="title">
                <h2 className="header">{props.header}</h2>
                <h2 className="par">{props.description}</h2>
            </div>
        </div>
    )
}

export default VisionCard
