import React from 'react'
import '../../App.css'
import './SupportUsSection.css'

function SupportUsSection() {
    return (
        //<h1 className="title">Support Us</h1>
        <div className="support-header">
                <h1 className="title">Support Us!</h1>
                <p>Looking to Partner with UTWind?</p>
        </div>
    )
}

export default SupportUsSection