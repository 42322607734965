import React from 'react'
import VisionCard from '../Components/Vision/VisionCard'
import './Vision.scss'

function Vision() {

    return (
        <div id="Vision">
            <div className="Vision">
                <h2 className="stupid">Vision</h2>
                <p className="intro-par">
                    "Our vision is to bring wind energy to light for students at the University of Toronto, so they will be prepared and inspired to light up the world in a sustainable way.
                    In other words, our direct focus is each other! By working together, we hope to develop our skills in the context of a project we are passionate about. 
                    The team is a place where knowledge is shared and passed on. But the project is designed to focus not just on what we learn today, but what we can achieve in designing a more sustainable future!"
                </p>
            </div>
            <div className="cards">
                <div className="container">
                    <div className="wrapper">
                        <ul className="items-1">
                            <VisionCard icon="/images/promote.svg" header="Research" 
                            description="Conduct and promote research on wind energy technology"/>
                            <VisionCard icon="/images/learn.svg" header="Learn" 
                            description="Provide a learning experience for students at the University of Toronto on wind energy"/>
                            <VisionCard icon="/images/grow.svg" header="Grow"
                            description="Grow the wind energy network in Canada, particularly among students."/>
                        </ul>
                        <ul className="items-2">
                            <VisionCard icon="/images/transition.svg" header="Transition" 
                            description="Promote a global transition to renewable energy, away from fossil fuels"/>
                            <VisionCard icon="/images/apply.svg" header="Apply" 
                            description="Investigate the applicability of wind turbine technology to remote areas with poor access to clean, reliable energy"/>
                            {/* <div className="intro-card">
                                <h2>
                                    "Our vision is to bring wind energy to light for students at the University of Toronto, so they will be prepared and inspired to light up the world in a sustainable way.
                                    In other words, our direct focus is each other! By working together, we hope to develop our skills in the context of a project we are passionate about. 
                                    The team is a place where knowledge is shared and passed on. But the project is designed to focus not just on what we learn today, but what we can achieve in designing a more sustainable future!"
                                </h2>
                            </div> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vision
