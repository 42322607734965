import { useEffect } from "react";

const Slider = (slideImage, slideText, slideTitle, images) => {
    let counter = 0;

    useEffect(() => startSlider()) //

    const startSlider = () => { //takes care of first slide in slide show
        slideImage.current.style.backgroundImage = `linear-gradient(
            to right,
            rgba(34, 34, 34, 0.4),
            rgba(68, 68, 68, 0.4)
            ), url(${images[0].image})`;
            slideTitle.current.innerHTML = images[0].title;
            slideText.current.innerHTML = images[0].desc;

    }

    const handleSlide = slide => { //takes a parameter called 'slide'
        slideImage.current.style.backgroundImage = `linear-gradient(
            to right,
            rgba(34, 34, 34, 0.4),
            rgba(68, 68, 68, 0.4)
            ), url(${images[slide - 1].image})`
          slideTitle.current.innerHTML = images[slide - 1].title
          slideText.current.innerHTML = images[slide - 1].desc
          animateSlide(slideImage)
    }
    
    const animateSlide = () => {
        slideImage.current.classList.add("fadeIn")
        setTimeout(() => {
          slideImage.current.classList.remove("fadeIn")
        }, 700)
    }

    const previousSlide = () => {
        if (counter === 0) {
            handleSlide(images.length)
            counter = images.length;
          }
        
        handleSlide(counter)         
        counter--;
    }

    const nextSlide = () => {
        if (counter === images.length - 1) {
          startSlider()
          counter = -1;
          animateSlide(slideImage)
        }

        slideImage.current.style.backgroundImage = `linear-gradient(
            to right,
            rgba(34, 34, 34, 0.4),
            rgba(68, 68, 68, 0.4)
            ),url(${images[counter + 1].image})`;
        slideTitle.current.innerHTML = images[counter + 1].title;
        slideText.current.innerHTML = images[counter + 1].desc;
        counter++;
        animateSlide(slideImage)
    }

  return { previousSlide, nextSlide }
}

export default Slider

