import React from 'react'
import "./GISform.css"

function GISwhy() {
    return (
        <div className="container">
          <div className="intro">
            <h1>Why Join UTWind?</h1>
            <p className="par">
              You will gain technical experience on a real wind turbine design project, the closest thing you will find on campus to what you will do in your career! Demonstrate to potential employers that you have the creative problem solving skills and ability to work on a team that they are looking for.
            </p>
          </div>
          <br/>
          <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FToronto&src=bXl1dHdpbmRAZ21haWwuY29t&src=ZjRiaGVtMXJ1NDVsa21jY25iMWlsMDJlbmNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=OGloZTRpMWgzbnE2YnE2ZjdvNGRlcjJvZGNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=MGZ1M3IzY3VlNWRnbG5tdXAxaTJtcjVzYjhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=dWRmMGloaXZmMHJqOXJzYWUwczZvY2I2dDBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=czdkdmNobG1rb3RvNjkxcXRnZnQwdHMxdGdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=Nm1xOGtuZm82YWVkcHFnYXFobDZtMHJnYThAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=N2gydWZnYjEyMWpka3FmdGhudTM0Y3ZyNmdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=dXRhdC5jYV83c3FocWg4bzdqNDUwbzBkNWxlNzRuMGVza0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%238E24AA&color=%233F51B5&color=%23F09300&color=%234285F4&color=%23009688&color=%23F4511E&color=%237CB342&color=%2333B679&color=%239E69AF" style={{width: '90%', height: 800, display: 'block', margin: 'auto', borderWidth: 2, borderColor: '#777', borderStyle: 'solid'}}></iframe>
      
          <div className="GISWhy">
              <div className="text">
                      
                  <h3 className="gisForm">Have Questions? </h3>
                  <h3 className="gisForm"> Get in Touch.</h3>
              </div>
                  
              <div class="form-container">
                <form class="question-form" action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSc1PIgdVWMF30m6HM2xMk0st6mY-030NuIWdpYo-PdiCQ88Sg/formResponse">
                {/*<label>Name:</label>*/}
                  <input
                    id="fame"
                    class="form-field"
                    type="text"
                    placeholder="Name"
                    name="entry.623459472"
                  />
                  {/* Uncomment the next line to show the error message */}
                  {/* <span id="error">Please enter your name</span> */}
                  <input
                    id="message"
                    class="form-field"
                    type="text"
                    placeholder="Message"
                    name="entry.930846472"
                  />
                  {/* Uncomment the next line to show the error message */}
                  {/*<span id="error">Please enter your message</span>*/}
                  <input
                    id="email"
                    class="form-field"
                    type="text"
                    placeholder="Email"
                    name="entry.1760212911"
                  />
                  {/* Uncomment the next line to show the error message */}
                  {/*<span id="error">Please enter an email address</span>*/}
                  <button class="form-field" type="submit">
                    Contact Us
                  </button>
                </form>

              </div>
          </div>
        </div>
    )
}

export default GISwhy